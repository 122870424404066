<template>
    <div
        :class="'country-flag d-flex justify-center align-center mx-1 ' + (active ? 'active' : '')"
        @click="$emit('flag-click')"
    >
        <img
            :src="`${$utils.globalConstants.BASE_ASSETS_URL}/countries_flags/${iso_code}.png`"
            alt=" country-flag"
            class="border-radius-1"
        />
    </div>
</template>
<script>
export default {
    name: 'country-flag',
    props: {
        iso_code: { type: String, default: '' },
        active: { type: Boolean, default: false },
    },
}
</script>
<style>
.country-flag img {
    width: 7.5vh;
}
.country-flag.active img{
    border: 3px solid #514773;
}
</style>
