<template>
    <v-card
        class="d-inline-block mx-auto border-radius-3 pa-4 ma-10 w-90 position-relative"
        elevation="11"
    >
        <v-container>
            <v-row class="d-flex flex-row ma-0 justify-center justify-md-start">
                <v-col cols="12" lg="4" class="d-flex align-center justify-center">
                    <div class="book-cover">
                        <img
                            :src="`${$utils.globalConstants.BASE_ASSETS_URL}/lbr/image/${book.resourceDir}/cover_medium.jpg`"
                            class="border-radius-2 cursor-pointer"
                            v-if="isBook"
                            @click="startReading"
                        />
                        <v-skeleton-loader
                            v-else
                            type="image"
                            width="180"
                            height="250"
                        />
                    </div>
                </v-col>
                <v-col cols="12" lg="8" class="text-center">
                    <v-row
                        class="flex-column ma-0 px-5 justify-center align-center justify-lg-start"
                    >
                        <v-col
                            class="px-0 text-left d-flex flex-column"
                            v-if="isBook && allPagesLoaded"
                        >
                            <v-card-text
                                class="text-subtitle-1 pa-0 brown--text darken-3"
                                color="red"
                            >
                                <span v-if="book.multipleAuthor">
                                    <span
                                        v-for="(author, index) of book.bookAuthorDTOList"
                                        :key="index"
                                    >
                                        <span v-if="index !== 0">, </span>
                                        {{ author.name }}
                                    </span>
                                </span>
                                <span v-else-if="book.authorDTO">
                                    {{ book.authorDTO ? book.authorDTO.name : $t('anonymous') }}
                                </span>
                                <span v-else>
                                    {{ $t('anonymous') }}
                                </span>
                            </v-card-text>
                            <v-card-text class="text-h4 brown--text darken-4 pa-0">
                                {{ book.title }}
                            </v-card-text>
                            <v-card-text class="text-body-1 brown--text darken-3 pa-0">
                                {{ book.bookAbstract }}
                            </v-card-text>
                        </v-col>
                        <v-col class="px-0 text-left d-flex flex-column" v-else>
                            <v-skeleton-loader
                                type="text"
                                width="130"
                                class="ma-0"
                            />
                            <v-skeleton-loader type="text" width="100"/>
                            <v-skeleton-loader type="text" width="200"/>
                            <v-skeleton-loader type="text" width="200"/>
                            <v-skeleton-loader type="text" width="200"/>
                            <v-skeleton-loader type="text" width="200"/>
                            <v-skeleton-loader type="text" width="200"/>
                        </v-col>

                        <v-col class="px-0" v-if="isBook">
                            <v-row class="row d-flex align-center" justify="center">
                                <v-col class="d-flex flex-column justify-start col align-start">
                                    <span class="mb-1 font-size-14 brown--text darken-3">
                                        {{ ratingMsg(rating)}}
                                    </span>
                                    <star-rating
                                        :star-size="23"
                                        :show-rating="false"
                                        rounded-corners
                                        v-model="rating"
                                        read-only
                                    />
                                </v-col>
                                <div class="special-border"/>
                                <v-col
                                    class="d-flex flex-row justify-center align-center cursor-pointer"
                                    @click="showAllComments = true"
                                >
                                    <v-img width="30" :src="comment"/>
                                    <v-card-text class="ml-2 text-left brown--text darken-4 pa-0">
                                        {{ countComments }}
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="px-0" v-else>
                            <v-row class="row d-flex align-center" justify="space-between">
                                <div class="px-0 text-left d-flex flex-column mx-5">
                                    <v-skeleton-loader
                                        type="text"
                                        width="130"
                                        class="ma-0"
                                    />
                                    <v-skeleton-loader
                                        type="text"
                                        width="130"
                                        class="ma-0"
                                    />
                                </div>
                                <div class="special-border"/>
                                <v-col class="d-flex flex-row justify-center align-center">
                                    <v-skeleton-loader
                                        type="text"
                                        width="50"
                                        class="mx-2"
                                    />
                                </v-col>
                                <div class="special-border"/>
                                <v-col class="d-flex flex-row justify-center align-center">
                                    <v-skeleton-loader
                                        type="text"
                                        width="50"
                                        class="mx-2"
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="px-0" v-if="isBook">
                            <v-row
                                class="d-flex flex-row"
                                align="center"
                                align-lg="start"
                                justify="space-around"

                            >
                                <x-ArrowButtonOutline
                                    :width="'190px'"
                                    :height="'10vh'"
                                    @brClick="$router.go(-1)"
                                >
                                    <span class="secondary--text">
                                        {{ $t('go_back') }}
                                    </span>
                                </x-ArrowButtonOutline>
                                <x-ArrowButton
                                    :width="'200px'"
                                    :height="'10vh'"
                                    @brClick="startReading"
                                >
                                    <span class="white--text">
                                        {{ $t('bookReading.start_reading') }}
                                    </span>
                                </x-ArrowButton>
                            </v-row>
                        </v-col>
                        <v-col class="px-0" v-else>
                            <v-row
                                class="d-flex flex-row"
                                align="center"
                                align-lg="start"
                                justify="center"
                                justify-lg="start"
                            >
                                <v-skeleton-loader
                                    type="button"
                                    width="100"
                                    class="mx-2"
                                />
                                <v-skeleton-loader
                                    type="button"
                                    width="100"
                                    class="mx-2"
                                />
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <Footer ref="initFooter" v-show="false" v-if="isBook"/>
        </v-container>
        <AllCommentsModal
            :dialog="showAllComments"
            :bookTitle="book.title"
            :totalRatings="totalRatings"
            @close-modal="showAllComments = false"
            v-if="isBook && showAllComments"
        />
    </v-card>
</template>
<script>
/* eslint-disable global-require */
/* eslint-disable no-restricted-globals */

import { mapState, mapActions } from 'vuex'
import global from '@/constants/GlobalConstant'

import AllCommentsModal from './modals/AllCommentsModal.vue'
import Footer from './content/Footer'

export default {
    props: {
        book: { type: Object, default: null },
        avgRating: { type: Number, default: 0 },
        userRating: { type: Object, default: null },
    },
    components: {
        Footer,
        AllCommentsModal,
    },
    data() {
        return {
            clock: require('@/assets/img/icons/clock.svg'),
            comment: require('@/assets/img/icons/comment.svg'),
            showAllComments: false,
        }
    },
    methods: {
        ...mapActions('bookReading', [
            'setCurrentStage',
            'setCurrentChapterData',
            'setCurrentPage',
            'setCurrentChapter',
        ]),
        startReading() {
            this.setCurrentStage('bookReading')
            this.setCurrentChapter(1)
            this.setCurrentPage(1)
            this.setCurrentChapterData(this.book.bookChapterDTOList[this.currentChapter - 1])
            this.showPage()
        },
        showPage() {
            document.querySelectorAll('.bookpage').forEach((element) => {
                const el = element
                el.style.display = 'none'
                el.style.flexDirection = 'column'
            })
            document.querySelectorAll('.ilustration img').forEach((element, index) => {
                element.setAttribute(
                    'src',
                    `${global.BASE_URL_BOOK}${this.book.resourceDir}/img/${index + 1}_L.jpg`
                )
            })

            if (this.book.formatType === 'EPUB') {
                document.querySelectorAll('.bookpage img').forEach((element) => {
                    let content = element.getAttribute('src')
                    if (content.includes('../')) {
                        content = content.replaceAll('../', '')
                    }
                    element.setAttribute(
                        'src',
                        `${global.BASE_URL_BOOK}${this.book.resourceDir}/OEBPS/${content}`
                    )
                })
            }

            const chapterHtml = document.querySelector(`#chapter_${this.currentChapter}`)
            if (chapterHtml) chapterHtml.style.display = 'block'
            document.querySelector(`#bookpage_${this.currentPage}`).style.display = 'flex'
            document.querySelector(`#bookpage_${this.currentPage}`).classList.toggle('current-page')

            this.$refs.initFooter.getQuestionsForPage()
        },
        ratingMsg(rating) {
            switch (rating) {
                case 3:
                    return this.$t('bookReading.good')
                case 4:
                    return this.$t('bookReading.very_good')
                case 5:
                    return this.$t('bookReading.excellent')
                default:
                    return this.$t('bookReading.qualification')
            }
        },
    },
    computed: {
        ...mapState({
            currentStage: (state) => state.bookReading.currentStage,
            currentPage: (state) => state.bookReading.currentPage,
            currentChapter: (state) => state.bookReading.currentChapter,
            currentChapterData: (state) => state.bookReading.currentChapterData,
            bookRatingData: (state) => state.bookReading.bookRatingData,
            totalRatings: (state) => state.bookReading.totalRatings,
            epubPagesLoaded: (state) => state.bookReading.epubPagesLoaded,
            pagesLoaded: (state) => state.bookReading.pagesLoaded,
            epubPages: (state) => state.bookReading.epubPages,
        }),
        allPagesLoaded() {
            if (this.book) {
                if (this.book.formatType === 'EPUB') {
                    return this.epubPagesLoaded === this.epubPages.length
                }

                return this.pagesLoaded === this.book.bookChapterDTOList.length
            }
            return false
        },
        isBook() {
            return this.book !== null
        },
        rating() {
            let result = null
            if (this.userRating) {
                result = this.userRating.rating
            } else {
                result = this.avgRating
            }
            return result
        },
        countComments() {
            let result = null
            if (this.bookRatingData) {
                if (this.bookRatingData.length < 1) result = this.$t('no_comment')
                else if (this.bookRatingData.length > 9) result = this.$t('more_nine_comments')
                else result = `${this.bookRatingData.length} ${this.$t('comments')}`
            } else result = this.$t('no_comment')

            return result
        },
    },
}
</script>
<style>
.special-border {
    border: 0px;
    border-left: 3px #4e342e;
    border-style: dotted;
    height: 4vh;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .book-cover img {
        width: 45vh;
        height: 55vh;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .book-cover img {
        width: 45vh;
        height: 55vh;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .book-cover img {
        width: 45vh;
        height: 55vh;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .book-cover img {
        width: 35vh;
        height: 45vh;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .book-cover img {
        width: 40vh;
        height: 50vh;
    }
}

.favorite-corner {
    right: 0;
    top: 0;
}
</style>
