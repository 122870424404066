<template>
    <v-dialog v-model="dialog" max-width="550" persistent>
        <div
            class="d-flex flex-column justify-center align-center elevation-15 higlight-type-content position-relative"
        >
            <v-btn color="primary" icon class="position-absolute btn-cls" @click="close">
                <v-icon color="primary">mdi-close-circle</v-icon>
            </v-btn>
            <div class="d-flex flex-column justify-center align-center position-relative pa-8">
                <div class="py-3 d-flex flex-column justify-center align-center" v-if="main_idiom">
                    <span class="text-subtitle-1 primary--text font-weight-bolder">{{
                        `¿Sabías que la palabra "${main_idiom.word}" tiene diferentes significado en ${main_idiom.countries.length} paises`
                    }}</span>
                </div>
                <v-row
                    class="main-idiom-img mb-5 d-flex flex-row justify-center align-center position-relative"
                >
                    <v-col cols="4" class="d-flex justify-end">
                        <img
                            :src="`${$utils.globalConstants.BASE_ASSETS_URL}/lingual_variable_image/${main_idiom.urlImg}`"
                            alt="imgen de variable linguistica"
                            class="rounded-circle variable-img"
                    /></v-col>
                    <v-col cols="8" class="d-flex justify-start">
                        <SplashContent
                            ><span
                                class="text-body-1 font-weight-bold primary--text ml-10 font-size-16"
                                v-html="
                                    `En
                            <b class=&quot;text-capitalize&quot;
                                >${currentCountry.country.name}</b
                            >
                            la palabra &quot;${currentCountry.word}&quot; se usa para referirse al
                            &quot;${main_idiom.word}&quot;`
                                "
                            ></span> </SplashContent
                    ></v-col>
                </v-row>
                <div
                    class="d-flex flex-row justify-center align-center flags-control w-80 mt-5"
                    v-if="main_idiom"
                >
                    <v-btn
                        icon
                        class="mx-3 secondary"
                        v-if="main_idiom.countries.length >= 5"
                        @click="moveRigth"
                        ><v-icon class="white--text icon-rotate">
                            mdi-trending-neutral</v-icon
                        ></v-btn
                    >
                    <div
                        :class="
                            'w-75 position-relative flags-content d-flex align-center flex-row ' +
                            (main_idiom.countries.length >= 5 ? '' : 'justify-center')
                        "
                        id="flags-carrousel"
                    >
                        <CountryFlag
                            :iso_code="country.country.isoCode"
                            :active="country.id === Number(current_country)"
                            :key="index"
                            v-for="(country, index) of main_idiom.countries"
                            class="pa-0"
                            @flag-click="setCountry(country.id)"
                        />
                    </div>
                    <v-btn
                        v-if="main_idiom.countries.length >= 5"
                        icon
                        class="mx-3 secondary"
                        @click="moveLeft"
                        ><v-icon class="white--text"> mdi-trending-neutral</v-icon></v-btn
                    >
                </div>
            </div>
        </div>
    </v-dialog>
</template>
<script>
/* eslint-disable global-require */
import SplashContent from '@/components/library/preview/core/SplashContent.vue'
import CountryFlag from '@/components/library/preview/core/CountryFlag.vue'

export default {
    components: {
        SplashContent,
        CountryFlag,
    },
    props: {
        dialog: { type: Boolean, default: false },
        main_idiom: { type: Object, default: null },
        current_country: { type: [Number, String], default: '' },
    },
    computed: {
        currentCountry() {
            const vm = this
            const result = this.main_idiom.countries.find(
                (el) => el.id === Number(vm.current_country)
            )
            return result
        },
    },
    data() {
        return {
            scrollValue: 0,
        }
    },
    methods: {
        close() {
            this.$emit('close-modal')
        },
        setCountry(id) {
            this.current_country = id
        },
        moveLeft() {
            const content = document.getElementById('flags-carrousel')
            if (content) {
                content.scrollLeft += 25
            }
        },
        moveRigth() {
            const content = document.getElementById('flags-carrousel')
            if (content) {
                content.scrollLeft -= 25
            }
        },
    },
    mounted() {
        const content = document.getElementById('flags-carrousel')
        if (content) {
            content.scrollLeft = content.scrollWidth / 2
        }
    },
}
</script>
<style>
.flags-content {
    overflow: hidden;
}

.flags-control {
    bottom: 2vh;
}

.main-idiom-img .variable-img {
    z-index: 10;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .main-idiom-img .variable-img {
        width: 17.5vh;
        height: 17.5vh;
        left: -3.5vh;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .main-idiom-img .variable-img {
        width: 20vh;
        height: 20vh;
        left: -3.5vh;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .main-idiom-img .variable-img {
        width: 17.5vh;
        height: 17.5vh;
        left: -3.5vh;
    }
}
</style>
