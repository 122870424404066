<template>
    <v-card
        class="d-inline-block mx-auto border-radius-3 pa-12 ma-10 w-90 position-relative"
        elevation="11"
    >
        <v-container>
            <v-row class="d-flex flex-row ma-0 justify-center justify-md-start">
                <v-col cols="12" lg="4" class="d-flex align-center justify-center">
                    <div class="book-cover">
                        <img
                            :src="`${$utils.globalConstants.BASE_ASSETS_URL}/lbr/image/${book.resourceDir}/cover_medium.jpg`"
                            class="border-radius-2"
                            v-if="isBook"
                            alt="book-cover"
                        />
                    </div>
                </v-col>
                <v-col cols="12" lg="8" class="pa-12 text-center">
                    <v-row
                        class="flex-column ma-0 px-5 justify-center align-center justify-lg-start"
                    >
                        <v-col class="px-0 text-center d-flex flex-column" v-if="isBook">
                            <v-card-text class="text-h4 brown--text darken-4 pa-0">
                                {{ $t('bookReading.congrats') }}
                            </v-card-text>
                            <v-card-text
                                class="text-subtitle-1 pa-0 brown--text darken-3"
                                color="red"
                            >
                                {{ $t('bookReading.congrats_msg_1') }}
                            </v-card-text>
                        </v-col>
                        <v-col class="px-0 mt-n5" v-if="isBook">
                            <v-row class="d-flex flex-row align-center justify-center">
                                <v-btn
                                    rounded
                                    color="secondary"
                                    class="px-12 py-5 my-12 mx-3"
                                    outlined
                                    @click="$router.go(-1)"
                                >
                                    {{ $t('bookReading.back_to_the_library') }}
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>
<script>
export default {
    props: {
        book: { type: Object, default: null },
    },
    computed: {
        isBook() {
            return this.book !== null
        },
    },
}
</script>
<style>
.special-border {
    border: 0px dotted;
    border-left: 3px #4e342e;
    height: 4vh;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .book-cover img {
        width: 45vh;
        height: 55vh;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .book-cover img {
        width: 45vh;
        height: 55vh;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .book-cover img {
        width: 45vh;
        height: 55vh;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .book-cover img {
        width: 35vh;
        height: 45vh;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .book-cover img {
        width: 40vh;
        height: 50vh;
    }
}
</style>
